<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-12">
        <div class="card rounded-lg border border-dark">
          <div class="row">
            <div class="col-md-6">
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Sempozyum Üst Kurul</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. İsmail KOYUNCU, İstanbul Teknik Üniversitesi Rektörü
                      </li>
                      <li class="card-text">
                        Dr. Mahmut AYDIN, Samsun Üniversitesi Rektörü
                      </li>
                      <li class="card-text">
                        Volkan Mutlu COŞKUN, MGM Genel Müdürü
                      </li>
                      <li class="card-text">
                        Dr. Ahmet Duran ŞAHİN, İTÜ Meteoroloji Müh. Böl. Başkanı
                      </li>
                      <li class="card-text">
                        Fırat ÇUKURÇAYIR, TMMOB Meteoroloji Mühendisleri Odası
                        Başkanı
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Sempozyum Yürütme Kurulu</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. Ahmet ÖZTOPAL - Başkan, İstanbul Teknik Üniversitesi
                      </li>
                      <li class="card-text">
                        Dr. Bahtiyar EFE - Başkan Yardımcısı, Samsun
                        Üniversitesi
                      </li>
                      <li class="card-text">
                        Dr. Bihter DURNA – Başkan Yardımcısı, Samsun
                        Üniversitesi
                      </li>
                      <li class="card-text">
                        Yük. Müh. Sema ÇİL, Meteoroloji Genel Müdürlüğü
                      </li>
                      <li class="card-text">
                        Yük. Müh. Emel ÜNAL, TMMOB Meteoroloji Mühendisleri
                        Odası
                      </li>
                      <li class="card-text">
                        Yük. Müh. Melek AKIN, İstanbul Teknik Üniversitesi
                      </li>
                      <li class="card-text">
                        Fatma Ekin HÜNER, İTÜ METEO UZAL Takımı
                      </li>
                      <li class="card-text">
                        Abdurrahman DURMAZ, İTÜ METEO UZAL Takımı
                      </li>
                      <li class="card-text">
                        Esra KAÇAR, İTÜ METEO UZAL Takımı
                      </li>
                      <li class="card-text">
                        Sema GÜNEŞLİK, İTÜ METEO UZAL Takımı
                      </li>
                      <li class="card-text">
                        Yağmur UZUN, İTÜ METEO UZAL Takımı
                      </li>
                      <li class="card-text">
                        Halil KARAKAYA, İTÜ METEO KODLAMA Takımı
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Çağrılı Konuşmacılar</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. Ali Nadir ARSLAN, Finnish Meteorological Institute
                      </li>
                      <li class="card-text">
                        Dr. Aynur Ş. ŞORMAN, Eskişehir Teknik Üniversitesi
                      </li>
                      <li class="card-text">
                        Dr. Daniele CASELLA, National Research Council of Italy
                      </li>
                      <li class="card-text">
                        Dr. İsmail YÜCEL, Ortadoğu Teknik Üniversitesi
                      </li>
                      <li class="card-text">
                        Dr. Neil I. FOX, University of Missouri
                      </li>
                      <li class="card-text">
                        Dr. Samuel MILLER, Plymouth State University
                      </li>
                      <li class="card-text">
                        Dr. Sante LAVIOLA, National Research Council of Italy
                      </li>
                      <li class="card-text">
                        Dr. Semih EKERCİN, Necmettin Erbakan Üniversitesi
                      </li>
                      <li class="card-text">
                        Dr. Zuhal AKYÜREK, Ortadoğu Teknik Üniversitesi
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Sempozyum Bilim Kurulu</h5>
                    <hr />
                    <ul>
                      <li>Dr. Abdullah KAHRAMAN, Newcastle Üniversitesi</li>
                      <li>Dr. Ahmet ÖZTOPAL, İstanbul Teknik Üniversitesi</li>
                      <li>
                        Dr. Ahmet Duran ŞAHİN, İstanbul Teknik Üniversitesi
                      </li>
                      <li>Dr. Ahmet Emre TEKELİ, Iğdır Üniversitesi</li>
                      <li>Dr. Ali DENİZ, İstanbul Teknik Üniversitesi</li>
                      <li>
                        Dr. Ali Nadir ARSLAN, Finnish Meteorological Institute
                      </li>
                      <li>Dr. Anthony LUPO, University of Missouri</li>
                      <li>
                        Dr. Aynur Şensoy ŞORMAN, Eskişehir Teknik Üniversitesi
                      </li>
                      <li>Dr. Bahtiyar EFE, Samsun Üniversitesi</li>
                      <li>Dr. Bihter DURNA, Samsun Üniversitesi</li>
                      <li>Dr. Ceyhan KÂHYA, İstanbul Teknik Üniversitesi</li>
                      <li>
                        Dr. Daniele CASELLA, National Research Council of Italy
                      </li>
                      <li>Dr. Deniz DEMİRHAN, İstanbul Teknik Üniversitesi</li>
                      <li>
                        Dr. Emrah Tuncay ÖZDEMİR, Meteoroloji Genel Müdürlüğü
                      </li>
                      <li>Dr. Elçin TAN, İstanbul Teknik Üniversitesi</li>
                      <li>
                        Dr. Emine Ceren K. EYİGÜLER, İstanbul Teknik
                        Üniversitesi
                      </li>
                      <li>
                        Dr. Filiz TÜRK KATIRCIOĞLU, İstanbul Teknik Üniversitesi
                      </li>
                      <li>Dr. Hakan AKSU, Samsun Üniversitesi</li>
                      <li>Dr. Hakan KOÇAK, Meteoroloji Genel Müdürlüğü</li>
                      <li>Dr. Haldun KARAN, TÜBİTAK MAM</li>
                      <li>Dr. Hüseyin TOROS, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. İsmail YÜCEL, Ortadoğu Teknik Üniversitesi</li>
                      <li>Dr. Kaan KALKAN, TÜBİTAK-UZAY</li>
                      <li>Dr. Kasım KOÇAK, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Kurtuluş ÖZTÜRK, Meteoroloji Genel Müdürlüğü</li>
                      <li>Dr. Levent ŞAYLAN, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Mikdat KADIOĞLU, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Nebiye MUSAOĞLU, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Neil I. FOX, University of Missouri</li>
                      <li>Dr. Samuel MILLER, Plymouth State University</li>
                      <li>
                        Dr. Sante LAVIOLA, National Research Council of Italy
                      </li>
                      <li>Dr. Semih EKERCİN, Necmettin Erbakan Üniversitesi</li>
                      <li>Dr. Sevinç ASİLHAN, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Şeyda Tilev, Abu Dhabi Polytechnic</li>
                      <li>Dr. Şinasi KAYA, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Tuğrul YILMAZ, Ortadoğu Teknik Üniversitesi</li>
                      <li>Dr. Yurdanur ÜNAL, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Zafer ASLAN, İstanbul Aydın Üniversitesi</li>
                      <li>Dr. Zerefşan KAYMAZ, İstanbul Teknik Üniversitesi</li>
                      <li>Dr. Zuhal AKYÜREK, Ortadoğu Teknik Üniversitesi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Kurullar",
  components: {},
};
</script>

<style scoped></style>
